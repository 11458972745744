import React from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import { useTranslation } from 'react-i18next'
import usePlacesAutocomplete from "use-places-autocomplete"

import { SearchIcon } from './icons'

export default function PlacesAutocomplete({
    location,
    handleSelect,
    autocompleteType,
} : {
    location: string | null,
    handleSelect: Function,
    autocompleteType: 'light' | 'full',
}) {

    const { t } = useTranslation()

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'cz' },
            //types: ['geocode'],
        }
    })

    if (autocompleteType === 'light') {
        return (
            <Autocomplete
                placeholder={t('search.wherePlaceholder')}
                freeSolo
                disableClearable
                onChange={(event, newValue) => {
                    handleSelect(event, newValue, 'location')
                }}
                onInputChange={(event, newInputValue) => {
                    handleSelect(event, newInputValue, 'location')
                    setValue(newInputValue)
                }}
                loading={status !== "OK"}
                loadingText={t('search.whereEmpty')}
                value={location ?? ''}
                inputValue={location ?? ''}
                options={status === "OK" ? data.map(({ description }) => description) : []}
                sx={{
                    fontSize: '1rem',
                    minHeight: 0,
                    paddingLeft: '0.25rem',
                    paddingRight: '0.25rem',
                    borderColor: 'transparent',
                    borderRadius: '0.25rem',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    lineHeight: '28px',
                    '--Input-focusedHighlight': 'var(--joy-palette-secondary-200)',
                    '&:hover:not(.Joy-focused)': {
                        borderColor: 'var(--joy-palette-secondary-200)',
                    }
                }}
                slotProps={{
                    listbox: {
                        sx: () => ({
                            zIndex: 2000,
                            backgroundColor: 'white',
                            minWidth: {
                                md: '300px !important',
                            },
                            inset: {
                                lg: '0 auto auto 50px !important',
                            },
                        }),
                    },
                }}
            />
        )
    } else {
        return(
            <Autocomplete
                startDecorator={<SearchIcon color="#BDBDBD"/>}
                placeholder={t('search.wherePlaceholder')}
                freeSolo
                disableClearable
                onChange={(event, newValue) => {
                    handleSelect(event, newValue, 'location')
                }}
                onInputChange={(event, newInputValue) => {
                    handleSelect(event, newInputValue, 'location')
                    setValue(newInputValue)
                }}
                loading={status !== "OK"}
                loadingText={t('search.whereEmpty')}
                value={location ?? ''}
                inputValue={location ?? ''}
                options={status === "OK" ? data.map(({ description }) => description) : []}
                sx={{
                    backgroundColor: 'white',
                    //boxShadow: 'none',
                    lineHeight: '28px',
                }}
                slotProps={{
                    listbox: {
                        sx: () => ({
                            zIndex: 2000,
                            backgroundColor: 'white',
                        })
                    }
                }}
            />
        )
    }
}
